/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: "Instrument Sans", sans-serif;
  font-weight: 500;
  background-color: #fff;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
button,
button:focus,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}
.form-control,
.form-control:focus {
  box-shadow: none !important;
  outline: none;
}
.btn:focus {
  box-shadow: none;
}
* {
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #ddd;
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 10px;
}
.pointer {
  cursor: pointer;
}
.errors {
  color: red;
  font-size: 12px;
}
.pac-container {
  z-index: 10000;
}

.re-agent-radiolist {
  display: inline-block;
  width: 100%;
  .re-radio {
    display: inline-block;
    position: relative;
    margin-bottom: 8px;
    margin-right: 8px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .re-agent-radiobox {
      display: inline-block;
      color: #01010b;
      font-size: 12px;
      font-weight: 500;
      padding: 6px 8px;
      background-color: #f2f2f2;
    }
    input:checked ~ .re-agent-radiobox {
      background-color: #0a4e81;
      color: #fff;
    }
  }
}

.re-radiolist,
.radiolist {
  width: 100%;
  display: inline-block;
  .re-radiobtn,
  .radiobtn {
    display: inline-block;
    position: relative;
    padding: 5px 0px 5px 24px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    color: #01010b;
    border-radius: 5px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }
  .re-checkmark,
  .checkmark {
    position: absolute;
    top: 3px;
    left: 1px;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #707070;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #0a4e81;
    }
  }
  input:checked ~ .re-checkmark,
  input:checked ~ .checkmark {
    border-color: #0a4e81;
  }
  input:checked ~ .re-checkmark:after,
  input:checked ~ .checkmark:after {
    display: block;
  }
}

.re-checkboxlist-1 {
  width: 100%;
  display: inline-block;
  .re-checkboxbtn {
    display: inline-block;
    position: relative;
    padding: 2px 0px 4px 24px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #01010b;
    border-radius: 5px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .re-checkmark {
      width: 14px;
      height: 14px;
      border-radius: 2px;
      position: absolute;
      top: 2px;
      left: 0;
      border: 1px solid #95989a;
      background-color: #fff;
    }
    .re-checkmark:after {
      content: "";
      width: 5px;
      height: 8px;
      position: absolute;
      left: 4px;
      top: 1px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    input:checked ~ .re-checkmark {
      border-color: #0a4e81;
    }
    input:checked ~ .re-checkmark:after {
      border-color: #0a4e81;
    }
  }
}

.re-checkboxlist {
  width: 100%;
  display: inline-block;
  .re-checkboxbtn {
    display: inline-block;
    position: relative;
    padding: 4px 0px 5px 24px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: #01010b;
    border-radius: 5px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .re-checkmark {
      width: 18px;
      height: 18px;
      border-radius: 2px;
      position: absolute;
      top: 2px;
      left: 0;
      border: 1px solid #8f8f8f;
      background-color: #fff;
    }
    .re-checkmark:after {
      content: "";
      width: 5px;
      height: 10px;
      position: absolute;
      left: 5px;
      top: 2px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    input:checked ~ .re-checkmark {
      background-color: #0a4e81;
      border-color: #0a4e81;
    }
  }
}

.modal-backdrop.in {
  filter: alpha(opacity=80);
  opacity: 0.8;
}
.p-calendar {
  background: #fff;
  color: #555;
  width: 100% !important;
  border-radius: 3px;
  border: 2px solid #f2f2f2;
  .p-inputtext {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #01010b;
    height: 31px;
    font-family: "Instrument Sans", sans-serif;
    border-radius: 3px;
    padding: 2px 10px 2px 8px;
    border: none;
    outline: none;
    box-shadow: none;
    &:hover {
      border: transparent !important;
    }
    &:disabled {
      background-color: #e9ecef;
      opacity: 1;
      color: #01010b;
    }
  }
  .p-datepicker {
    width: 17em;
  }
  .p-button {
    &:enabled {
      &:hover {
        color: #95989a;
      }
    }
  }
  .p-inputtext:disabled {
    background-color: #e9ecef;
    opacity: 1;
    color: #01010b;
  }
}

.ngCalendarInput {
  width: 100%;
}

.ngCalendarClass {
  width: 100%;
  border: 2px solid #f2f2f2;
  .p-datepicker {
    min-width: 100% !important;
    padding: 0.357rem;
    text-align: -webkit-center;
    table {
      width: 100% !important;
      font-size: 0.8rem;
      margin: 0.457rem 0;
      td {
        padding: 0.3rem;
        span {
          width: 1.2rem;
          height: 1.2rem;
          box-shadow: none;
          user-select: none;
        }
      }
    }
    .p-datepicker-header {
      .p-datepicker-title {
        line-height: 1rem;
      }
      .p-datepicker-prev {
        width: 1rem;
        height: 1rem;
        .pi {
          font-size: 0.8rem;
        }
      }
      .p-datepicker-next {
        width: 1rem;
        height: 1rem;
        .pi {
          font-size: 0.8rem;
        }
      }
    }
  }
  .p-datepicker-header {
    padding: 0.1rem;
    font-size: 0.8rem;
    padding: 0.1rem;
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight,
  .p-datepicker table td > span.p-highlight {
    background-color: #0a4e81;
    box-shadow: none;
  }
}

.p-datepicker {
  padding: 0.4rem;
  min-width: auto !important;
  .p-datepicker-header {
    padding: 0.2rem;
    .p-datepicker-prev {
      width: 1.5rem;
      height: 1.5rem;
    }
    .p-datepicker-next {
      width: 1.5rem;
      height: 1.5rem;
    }
    .p-datepicker-title {
      line-height: 1.2;
      .p-datepicker-year {
        padding: 0 !important;
      }
      .p-datepicker-month {
        padding: 0 !important;
      }
    }
  }
  table {
    th {
      text-align: center !important;
      padding: 0.4rem;
      z-index: 99999 !important;
      min-width: auto !important;
      width: auto !important;
      table {
        th {
          span {
            width: 2rem;
            height: 2rem;
          }
        }
        td {
          padding: 0.1rem;
          span {
            width: 1.2rem;
            height: 1.2rem;
            font-size: 0.8rem;
          }
          span.p-highlight {
            background-color: #0a4e81;
            box-shadow: none;
          }
        }
        td.p-datepicker-today {
          span.p-highlight {
            background-color: #0a4e81;
            box-shadow: none;
          }
        }
      }
      .p-datepicker-buttonbar {
        padding: 0.157rem 0 0.129rem 0;
      }
    }
    td {
      padding: 0.2rem;
      span {
        width: 1.2rem;
        height: 1.2rem;
        font-size: 0.8rem;
      }
      span.p-highlight {
        background-color: #0a4e81;
        box-shadow: none;
      }
    }
    td.p-datepicker-today {
      span.p-highlight {
        background-color: #0a4e81;
        box-shadow: none;
      }
    }
  }
}
.p-datepicker-group-container {
  width: 100% !important;
}

.p-inputtext {
  padding: 0.232rem;
  border: 1px solid rgb(166, 166, 166, 0.3);
}
.p-inputtext:enabled:hover {
  border-color: rgba(166, 166, 166, 0.3);
}
.mat-mdc-tab-header {
  border-bottom: none !important;
  margin-bottom: 10px;
}
.re-agent-addshowing p-autocomplete .p-autocomplete {
  width: 100%;
}

.re-agent-custom p-autocomplete .p-autocomplete {
  width: 100%;
  padding: 6px;
}
.p-autocomplete-panel {
  z-index: 10000 !important;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  white-space: normal;
  font-family: "Instrument Sans", sans-serif;
  span {
    word-break: break-all;
  }
}
.re-large-autoComplete .p-autocomplete {
  height: 60px;
}
.mat-mdc-tab-label-container {
  .mat-mdc-tab-labels .mat-mdc-tab {
    flex-grow: 0 !important;
    letter-spacing: normal !important;
    -webkit-font-smoothing: auto !important;
    margin-right: 25px !important;
    padding: 0 !important;
    min-width: auto !important;
    height: 43px !important;
    &.mdc-tab--active {
      .mdc-tab__text-label {
        color: #0a4e81 !important;
      }
      .mdc-tab-indicator__content--underline {
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #0a4e81;
        right: 0;
        bottom: 0;
        border: none;
      }
    }
  }
}

.re-leads-add-edit-middle .mat-mdc-tab-label-container {
  padding: 0px !important;
}

.p-highlight .pi-check {
  color: #ffffff !important;
}
quill-editor {
  .ql-container {
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #f2f2f2 !important;
    padding-right: 40px;
    .ql-editor {
      min-height: 45px;
      max-height: 66px;
    }
  }
}

.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}

.mat-mdc-dialog-content {
  display: block;
  margin: auto !important;
  padding: 0 !important;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mat-primary {
  background: #0a4e81 !important;
}
.mat-mdc-dialog-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  flex-shrink: unset !important;
  letter-spacing: normal !important;
  padding: 8px 0 !important;
}
.mat-mdc-tab-list {
  border-bottom: 2px solid #f2f2f2;
}
.mat-mdc-tab-group * {
  font-family: "Instrument Sans", sans-serif;
}
.mat-mdc-tab-group .pi {
  font-family: "primeicons";
}
.mat-mdc-tab-group .fa {
  font-family: "FontAwesome";
}

.mat-mdc-tab-label {
  padding: 0px 0px !important;
  min-width: auto !important;
  opacity: 1 !important;
  color: #01010b !important;
  margin-right: 25px;
}
.mat-mdc-autocomplete-panel {
  background-color: #fff !important;
}
.re-chip-list {
  width: 100%;
  background-color: transparent !important;
  .mat-mdc-text-field-wrapper {
    padding: 0;
    .mat-mdc-form-field-flex {
      padding: 10px;
      border-radius: 3px;
      border: solid 2px #f2f2f2;
      background-color: #ffffff;
      .mat-mdc-chip .mat-mdc-chip-action-label {
        color: #fff !important;
      }
      .mat-mdc-form-field-infix {
        padding: 0;
      }
      .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
        .mdc-evolution-chip__icon--trailing {
        color: #fff !important;
      }
    }
    .mdc-line-ripple {
      display: none;
    }
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}
.mat-mdc-tab-label.active,
.mat-mdc-tab-label:focus {
  background: none !important;
}
.mat-mdc-ink-bar {
  background-color: #0a4e81 !important;
  height: 3px !important;
}
.mat-mdc-tab-label-active .mat-mdc-tab-label-content {
  color: #0a4e81 !important;
}

.p-autocomplete:focus,
.p-autocomplete.active {
  outline: none !important;
  outline: 0 !important;
}

.re-large-autoComplete .p-autocomplete-multiple-container {
  height: 54px;
  overflow-y: auto;
  align-items: start !important;
}
.p-button:enabled:hover {
  background: transparent !important;
  border: transparent !important;
}
.p-autocomplete-dd .p-autocomplete-input {
  border: transparent !important;
  font-size: 13px;
  padding-left: 10px;
  font-weight: 500;
}
.p-autocomplete {
  border: 2px solid #f2f2f2 !important;
  border-radius: 3px !important;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container,
.p-autocomplete {
  width: 100%;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  border: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 52px;
}
.p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token
  input {
  font-size: 0.8rem;
  width: 100%;
}
.p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  span {
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.p-button {
  padding: 0.229rem 0.5rem;
  font-size: 0.8rem;
  color: #95989a;
  background: transparent;
  border: transparent;
}
.pi {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #95989a !important;
}

.p-checkbox .p-checkbox-box {
  box-shadow: none !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #a6a6a6;
}
.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #0a4e81;
  border-color: #0a4e81;
}

// Multiselect Start
.p-multiselect {
  box-shadow: none !important;
}
.p-multiselect.p-disabled {
  background-color: #e9ecef;
}
.p-multiselect .p-multiselect-label {
  min-height: 28px;
  line-height: 1.1;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  box-shadow: none !important;
  white-space: normal;
  max-width: 550px;
  span {
    word-break: break-word;
  }
  &.p-highlight {
    color: #333333;
    background: #f9f9f9;
  }
}

.mat-mdc-focused .placeholder {
  color: transparent;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  background: #0a4e81;
}
.mat-mdc-form-field-appearance-legacy {
  .mat-mdc-form-field-underline {
    background: none;
  }
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
  }
}
.mat-mdc-form-field.mat-mdc-focused {
  .mat-mdc-form-field-ripple {
    background: none;
    height: 0;
    transition: none;
  }
}
.mat-mdc-form-field-ripple {
  transition: none;
}

.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}
.p-dropdown-panel {
  z-index: 99999 !important;
  position: fixed !important;
  display: block !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: normal;
  max-width: 550px;
  span {
    word-break: break-word;
  }
  .re-drtxt1 {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 3px;
  }
  .re-drtxt2 {
    display: block;
    font-weight: 400;
    font-size: 12px;
  }
  &.p-highlight {
    background: #0a4e81;
  }
}
.dropdown {
  margin-right: 16px !important;
}
.p-dropdown {
  width: 100%;
  min-height: 36px;
  box-shadow: none !important;
  outline: none !important;
  border: solid 2px #f2f2f2 !important;
  .p-dropdown-label {
    padding-top: 9px;
    font-size: 13px;
    padding-left: 8px;
    line-height: 1;
  }
}

.re-text,
.re-agent-text {
  ol {
    padding-left: 20px;
    li {
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
        list-9;
      counter-increment: list-0;
      &:before {
        content: counter(list-0, decimal) ". ";
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }
      &.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-1;
        padding-left: 1.5em;
        &:before {
          content: counter(list-1, lower-alpha) ". ";
        }
      }
      &.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-2;
        padding-left: 2.5em;
        &:before {
          content: counter(list-2, lower-roman) ". ";
        }
      }
    }
  }
  ul {
    list-style: disc;
    padding-left: 20px;
  }
}

// Mat Radio Button
.mat-mdc-radio-button.mat-accent {
  .mat-radio-label-content {
    font-size: 14px;
  }
}
.mat-mdc-radio-button {
  .mdc-radio {
    padding: 0 !important;
    .mdc-radio__native-control {
      &:enabled {
        &:checked + .mdc-radio__background {
          .mdc-radio__outer-circle {
            border-color: #0a4e81 !important;
          }
        }
      }
      &:enabled + .mdc-radio__background {
        .mdc-radio__inner-circle {
          background: #0a4e81 !important;
          border-style: none;
        }
      }
    }
    &:hover {
      .mdc-radio__native-control {
        &:not([disabled]) {
          &:not(:focus) ~ .mdc-radio__background {
            &::before {
              opacity: 0 !important;
            }
          }
        }
      }
    }
  }
}
.mat-mdc-radio-button.mat-mdc-radio-checked {
  .mdc-radio__background {
    &::before {
      background: #fff !important;
    }
  }
}

.re-agent-navbar .p-autocomplete .p-autocomplete-panel {
  width: 100%;
}
.re-agent-navbar .p-autocomplete-item {
  white-space: normal;
}
.re-agent-navbar
  .p-autocomplete-items
  .p-autocomplete-item
  + .p-autocomplete-item {
  border-top: 1px solid #ddd;
}
.re-agent-navbar .navbar-form p-autocomplete .p-autocomplete {
  border: none !important;
}
.re-agent-navbar .navbar-form p-autocomplete .p-inputtext {
  border: none;
  padding: 8px 5px;
  color: #95989a;
  font-size: 14px;
  font-weight: 500;
  font-family: "Instrument Sans", sans-serif;
  width: 200px;
  border-radius: 21px;
}

.p-tooltip {
  z-index: 10000 !important;
}
.p-tooltip .p-tooltip-text {
  font-family: "Instrument Sans", sans-serif;
  font-size: 13px;
  word-break: break-word;
}

.toast-container .ngx-toastr {
  font-weight: normal;
  line-height: 1.3;
}

.re-filter-dialog .mat-primary .mat-mdc-tab-header {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 0;
  z-index: 9;
  background-color: #fff;
  width: 100%;
}

.dropdown-menu.mention-menu .mention-item {
  font-size: 14px;
}

.re-teal,
.re-text a {
  color: #0a4e81;
  &:hover {
    text-decoration: underline;
  }
}

// Popover Start
.popover-none .popover {
  display: none !important;
}
.popover {
  z-index: 10000;
  line-height: 1.2;
  border: none;
  max-width: 290px;
  background-color: #fff;
  font-family: "Instrument Sans", sans-serif;
  box-shadow: 0 3px 30px 0 rgba(1, 1, 11, 0.2);
  border: solid 1px rgba(229, 229, 229, 0.5);
  .popover-body {
    padding: 0;
  }
  .popoverbody {
    .re-custom_popover {
      width: 100%;
      float: left;
      padding: 15px;
      overflow: auto;
      max-height: 70vh;
      .re-popover_close {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
        z-index: 1;
      }
      .poplabel {
        width: 100%;
        display: inline-block;
        position: relative;
        margin-bottom: 15px;
        &:before {
          content: "";
          background-color: #e5e5e5;
          height: 1px;
          width: 100%;
          position: absolute;
          left: 0;
          top: 13px;
        }
        span {
          position: relative;
          display: inline-block;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.2;
          color: #01010b;
          padding: 5px 15px;
          border-radius: 13px;
          background-color: #f2f2f2;
        }
      }
      .re-box-pad {
        float: left;
        width: 100%;
        padding-bottom: 10px;
        &:last-child {
          padding-bottom: 0;
        }
        .re-card-open {
          display: inline-block;
          margin-bottom: 8px;
          margin-right: 5px;
        }
        .re-card-hot {
          display: inline-block;
          color: #800000;
          font-size: 12px;
          font-weight: 500;
          padding: 2px 6px;
          margin-bottom: 8px;
          margin-right: 5px;
          border-radius: 2px;
          background-color: rgba(128, 0, 0, 0.1);
        }
        .re-owner-name {
          color: #01010b;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.1;
          padding-bottom: 3px;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
        .re-numb-mail {
          color: #95989a;
          font-size: 13px;
          font-weight: 600;
          line-height: 1.1;
          padding-bottom: 15px;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .re-created {
          color: #95989a;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.2;
          display: inline-block;
          b {
            font-weight: 500;
          }
        }
        .re-lable-title {
          color: #01010b;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.1;
          padding-bottom: 4px;
          display: block;
        }
        .re-address {
          color: #0a4e81;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.1;
          padding-bottom: 3px;
          display: block;
          cursor: pointer;
        }
        .re-area-details {
          font-size: 13px;
          color: #95989a;
          font-weight: 500;
          display: block;
        }
        .re-infobox {
          display: flex;
          flex-wrap: wrap;
          .re-infobox-list {
            width: 50%;
            padding: 0 5px 10px 0;
            float: left;
            word-break: break-word;
          }
          .re-txt1 {
            color: #01010b;
            font-size: 13px;
            font-weight: 600;
            line-height: 1.1;
            margin-bottom: 4px;
            display: block;
          }
          .re-txt2 {
            color: #01010b;
            font-size: 13px;
            font-weight: normal;
            line-height: 1.1;
            display: block;
          }
        }
        .re-label-1 {
          color: #01010b;
          font-size: 13px;
          font-weight: 600;
          line-height: 1.1;
          margin-bottom: 3px;
        }
        .re-label-info {
          color: #95989a;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.1;
        }
        .re-assign {
          color: #01010b;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          margin-bottom: 6px;
          display: block;
        }
        .re-memberlist {
          float: left;
          margin-right: 5px;
          .re-assign-user {
            float: left;
            margin-right: 5px;
          }
          img {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: solid 2px #ffffff;
            float: left;
            background-color: #eee;
          }
          .re-name-txt {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            float: left;
            padding: 6px 2px;
            font-size: 15px;
            font-weight: 500;
            color: #0a4e81;
            text-align: center;
            background-color: #0a4e8110;
          }
        }
        .re-allmember {
          color: #01010b;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          margin: 6px;
          display: inline-block;
        }

        .re-tagslist {
          float: left;
          width: 100%;
          .re-newtag {
            color: #01010b;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.1;
            display: inline-block;
            padding: 3px 5px;
            margin: 3px 2px;
            border-radius: 5px;
            background-color: #f2f2f2;
            word-break: break-all;
          }
          .re-moretag {
            color: #0a4e81;
            font-size: 13px;
            font-weight: 600;
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }
    .re-action-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background-color: #f5f5f5;
      .re-link {
        float: left;
        cursor: pointer;
        opacity: 0.7;
        filter: grayscale(1);
        &:hover,
        &.re-active {
          opacity: 1;
          filter: grayscale(0);
        }
        img {
          width: 30px;
          height: 30px;
          float: left;
        }
      }
      .re-link-trash {
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 17px;
        border: solid 1px rgba(128, 0, 0, 0.1);
        background-color: rgba(128, 0, 0, 0.05);
        cursor: pointer;
      }
    }
  }
}

.change-drop-menu {
  min-width: 100px !important;
  padding: 0 !important;
  border-radius: 3px !important;
  border: none;
  overflow: inherit !important;
  box-shadow: 0 0 15px 0 rgba(1, 1, 11, 0.1) !important;
  .re-links {
    display: flex;
    padding: 8px;
    color: #01010b;
    font-size: 15px;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    .re-icon {
      display: block;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      &.re-active {
        display: none;
      }
    }
    &:hover {
      background: #0a4e81;
      color: #fff;
      .re-icon {
        display: none;
        &.re-active {
          display: block;
        }
      }
    }
  }
  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

.modal-backdrop {
  z-index: 9001;
}
.modal {
  z-index: 9002;
}

.re-module-permissions {
  .mat-mdc-dialog-container {
    overflow-y: auto !important;
  }
}

.mat-mdc-accent .mat-mdc-slider-track-fill,
.mat-mdc-accent .mat-mdc-slider-thumb,
.mat-mdc-accent .mat-mdc-slider-thumb-label {
  background: #0a4e81;
}

.mat-mdc-tab-group {
  float: left;
  width: 100%;
  background: transparent !important;
  .mat-mdc-tab {
    .mat-ripple {
      display: none;
    }
    .mdc-tab__ripple::before {
      opacity: 0 !important;
      background-color: transparent !important;
    }
    .mdc-tab__text-label {
      color: #01010b !important;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0;
    }
  }
}
.mat-mdc-tab-label-active {
  .mat-mdc-tab-label-content {
    color: black;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #0a4e81;
      right: 0;
      bottom: 0;
    }
  }
}

mat-mdc-ink-bar {
  display: none !important;
}

.re-backdrop {
  background: rgb(0, 0, 0, 0.85) !important;
}

.re-preview-media .mat-mdc-dialog-surface {
  background-color: transparent !important;
}

.re-preview-media {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  mat-dialog-container {
    background: transparent !important;
    box-shadow: none !important;
  }
  .re-header-container {
    .re-header {
      background: rgb(0, 0, 0, 0.4);
      .re-header-title {
        background: transparent;
      }
    }
  }
  .carousel {
    height: 100%;
    .carousel-inner {
      height: 100%;
      width: 80%;
      margin: auto;
      .carousel-item {
        height: 100%;
      }
      img,
      video,
      audio,
      iframe {
        max-height: 90%;
        height: 90%;
        object-fit: scale-down;
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 10%;
      position: absolute;
    }
  }
}

.scrollhide ::-webkit-scrollbar {
  width: 0px;
}
.scrollhide #wrapper {
  min-height: inherit;
  padding-right: 0;
}

.checkboxlist {
  width: 100%;
  display: inline-block;
  .checkboxbtn {
    display: inline-block;
    position: relative;
    padding: 4px 0px 5px 24px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: #01010b;
    border-radius: 5px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .timing {
      color: #95989a;
      font-weight: normal;
      display: block;
    }
    .re-checkmark,
    .checkmark {
      width: 18px;
      height: 18px;
      border-radius: 2px;
      position: absolute;
      top: 2px;
      left: 0;
      border: 1px solid #8f8f8f;
      background-color: #fff;
    }
    .re-checkmark:after,
    .checkmark:after {
      content: "";
      width: 5px;
      height: 10px;
      position: absolute;
      left: 5px;
      top: 2px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    input:checked ~ .re-checkmark,
    input:checked ~ .checkmark {
      background-color: #0a4e81;
      border-color: #0a4e81;
    }
  }
}

// Calendar Start
.fc-direction-ltr {
  box-shadow: 0 0 20px 0 rgb(1 1 11 / 10%);
  background-color: #ffffff;
  border-top: 5px solid #0a4e81;
  padding: 10px;
  margin-bottom: 30px;
  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px !important;
  }
  .fc-toolbar {
    .fc-toolbar-chunk {
      .fc-today-button {
        font-size: 12px;
        font-weight: 500;
        padding: 8px 10px;
        opacity: 1 !important;
      }
      .fc-toolbar-title {
        font-size: 20px;
        color: #01010b;
        margin: 0;
      }
      .fc-button-group {
        .fc-button {
          font-size: 12px;
          font-weight: 500;
          padding: 8px 10px;
          .fc-icon {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .fc-view-harness {
    .fc-view {
      .fc-scrollgrid {
        thead {
          .fc-scrollgrid-section {
            th {
              background: #fff;
            }
            .fc-day-today {
              background-color: #0a4e81 !important;
            }
          }
        }
        tbody {
          tr {
            .fc-timegrid-divider {
              background: #f2f2f2;
            }
            td {
              a {
                color: #007bff;
              }
            }
            .fc-day-today {
              .fc-daygrid-day-frame {
                background-color: #dcecf7 !important;
                .fc-daygrid-day-number {
                  color: #0a4e81;
                }
              }
            }
          }
        }
      }
    }
    .fc-timeGridWeek-view {
      .fc-scrollgrid {
        tbody {
          tr {
            .fc-day-today {
              .fc-timegrid-col-frame {
                background-color: #dcecf7 !important;
              }
            }
          }
        }
      }
    }
  }
  .fc-scrollgrid,
  .fc-scrollgrid td,
  .fc-scrollgrid th {
    border-color: #f2f2f2 !important;
  }
  .fc-scrollgrid {
    td.fc-timegrid-axis.fc-scrollgrid-shrink {
      opacity: 0;
    }
    .fc-day-sun .fc-col-header-cell-cushion {
      color: #800000;
    }
    .fc-col-header-cell-cushion {
      padding: 6px 8px;
      color: #95989a;
      font-weight: 500;
    }
    .fc-day .fc-daygrid-day-top .fc-daygrid-day-number {
      color: #01010b;
      padding: 6px;
    }
  }
  .fc-timeGridWeek-view tbody tr.fc-scrollgrid-section:first-child {
    visibility: collapse;
  }
  .fc-timeGridWeek-view .fc-col-header .fc-day-today {
    background-color: #0a4e81;
    a {
      color: #ffffff;
    }
  }
  .fc-timeGridDay-view tbody tr.fc-scrollgrid-section:first-child {
    visibility: collapse;
  }
  .fc-timeGridDay-view .fc-col-header .fc-day-today {
    background-color: #0a4e81;
    a {
      color: #ffffff;
    }
  }
  .fc-timeGridDay-view {
    .fc-timegrid-col.fc-day-today,
    .fc-daygrid-day.fc-day-today {
      background-color: transparent !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .scrollhide #wrapper {
    padding-right: 10px;
  }
  .fc-direction-ltr .fc-scrollgrid .fc-timegrid-axis-cushion {
    font-size: 12px;
  }
  .fc-direction-ltr .fc-scrollgrid .fc-timegrid-slot-label-cushion {
    font-size: 12px;
  }
  .fc-direction-ltr .fc-scrollgrid .fc-col-header-cell-cushion {
    font-size: 12px;
  }
  .fc-direction-ltr
    .fc-scrollgrid
    .fc-day
    .fc-daygrid-day-top
    .fc-daygrid-day-number {
    font-size: 12px;
  }
  .fc-direction-ltr .fc-toolbar-chunk .fc-toolbar-title {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .fc-direction-ltr {
    min-width: 500px;
  }
}
.mat-mdc-menu-content {
  -webkit-font-smoothing: auto;
  line-height: 1;
  font-family: "Instrument Sans", sans-serif;
  letter-spacing: normal;
}
.mdc-dialog__surface {
  overflow-y: inherit !important;
}
.mdc-dialog__title::before {
  height: auto !important;
}
.mat-mdc-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  // background: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  overflow: hidden;
  .mdc-dialog__content {
    font-family: "Instrument Sans", sans-serif !important;
    line-height: 1 !important;
    letter-spacing: normal !important;
  }
}
.mat-mdc-unelevated-button[disabled] {
  background: #95989a !important;
  color: #fff !important;
}
.mdc-button {
  -webkit-font-smoothing: auto !important;
  letter-spacing: normal !important;
}

.ql-snow.ql-toolbar {
  .ql-formats {
    margin-right: 0px !important;
  }

  button {
    margin-right: 5px;
    &.ql-active {
      background: #0a4e81 !important;
      border-radius: 5px;
      &:hover {
        .ql-stroke {
          stroke: #fff !important;
        }

        .ql-fill {
          fill: #fff !important;
        }
      }
      .ql-stroke {
        stroke: #fff !important;
      }
      .ql-fill {
        fill: #fff !important;
      }
    }

    &:hover {
      .ql-stroke {
        stroke: #0a4e81 !important;
      }
      .ql-fill {
        fill: #0a4e81 !important;
      }
    }
  }
}

.mention-active > a {
  background-color: #0a4e81 !important;
}

.re-tabdiv-content .re-agent-media .re-text .re-highlight,
span.mention {
  background: #dcecf7 !important;
  color: #0a4e81 !important;
  border-radius: 3px !important;
  padding: 1px 4px 1px 4px !important;
}

.ql-mention-list {
  background-color: white;
  list-style-type: none;
  transform: translateY(-2px);
  margin-top: 0;
  z-index: 100;
  .ql-mention-list-item {
    cursor: pointer;
    padding: 10px 10px;
    &.selected {
      background-color: #0a4e81 !important;
      color: #fff;
    }
  }
}

.ql-mention-list-container {
  z-index: 1;
}

.custom_pagination {
  text-align: center;
  width: 100%;
  display: inline-block;
  .re-bold-500 {
    font-weight: 500;
  }
}

// googleMap Start
.re-new-googleMap .mat-mdc-dialog-container {
  margin-top: 40px;
  overflow: inherit !important;
}

//apex chart
.apexcharts-legend-series {
  cursor: pointer;
  line-height: unset !important;
}

//material

.mat-mdc-radio-button.mat-accent {
  .mdc-form-field {
    margin-bottom: 10px;
  }
}

.mdc-switch {
  &:enabled {
    .mdc-switch__track {
      &::after {
        background-color: #0a4e81 !important;
      }
    }
  }
  &.mdc-switch--selected {
    &:enabled {
      .mdc-switch__handle {
        &::after {
          background: #eeeded !important;
        }
      }
    }
  }
  .mdc-switch__icons {
    display: none;
  }
}

//Permission Denied
.re-agent-permission-title {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;
  position: relative;
  .re-agent-permission-listgrid {
    float: left;
    width: 100%;
    padding-top: 12px;
  }
  .re-agent-permission-unauthorized {
    width: 99%;
    display: inline-block;
    background-color: #ffffff;
    margin-left: 1%;
    padding: 10px;
    border-radius: 3px;
    text-align: center;
    a {
      color: #0a4e81;
    }
  }

  .re-agent-permission-unauthorizeddiv {
    display: inline-block;
    border: 1px solid #eeeeee;
    padding: 50px 20px;
    width: 100%;
    max-width: 380px;
    margin: 70px 0 20px;
    box-shadow: 0 0 18px 1px #f5abab;
  }

  .re-agent-permission-unauthorizeddiv img {
    max-width: 150px;
    margin-bottom: 20px;
  }

  .re-agent-permission-unauthorizeddiv h2 {
    font-weight: 700;
    line-height: 1.4;
    font-size: 20px;
    color: #666666;
  }

  .re-agent-permission-unauthorized h5 {
    font-weight: 600;
    max-width: 370px;
    margin: 0 auto 70px;
    line-height: 1.4;
    font-size: 13px;
    color: #666666;
  }

  .re-agent-permission-unauthorized h6 {
    font-weight: 600;
    line-height: 1.3;
    font-size: 11px;
  }
}

.re-capitalize {
  text-transform: capitalize;
}

.re-tooltip-text {
  padding: 5px;
  max-width: 370px;
}

.grafes .highcharts-button,
.highcharts-credits {
  display: none !important;
}
.highcharts-container .highcharts-root {
  font-family: "Rubik", sans-serif !important;
}

.re-lead-dialer-minimize {
  top: 0;
  right: 0;
  width: calc(100% - 60px) !important;
  max-width: 100% !important;
  .mdc-dialog__surface {
    border-bottom: solid 1px #99bcdb !important;
    background-color: #0a4e81 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 10px !important;
  }
}
